import React from 'react';
import './LoopBack.css';
import logo from './logo.svg';

const LoopBack = () => {
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                <h1 className="main__title">Consulting-prof.com</h1>
                <p>Страница находится в разработке</p>
                <p>
                    <h2>ООО "Консалтинг - Проф"</h2>
                </p>
                <p>Контакты</p>
                <p>
                    344082, Ростовская обл., г. Ростов-на-Дону, ул. Шаумяна, д. 36А, этаж 3, комн.
                    309
                </p>
                <p>
                    <a href="mailto:audit@consulting-prof.com">audit@consulting-prof.com</a>
                </p>
            </header>
        </div>
    );
};

export default LoopBack;
