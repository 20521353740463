import React from 'react';
import LoopBack from './components/LoopBack';
import { Routes, Route, useLocation } from 'react-router-dom';

function App() {
    return (
        <Routes>
            {/* <Route path="/" element={<MenuHeader bgActive={!isPadding} />}> */}
            <Route index element={<LoopBack />} />
            {/* <Route path="game/*" element={<GamePage bgActive={isPadding} />} />
             <Route path="contact" element={<ContactPage />} />
             <Route path="about" element={<AboutPage />} />
             <Route path="*" element={<NotFound />} /> */}
            {/* </Route> */}
        </Routes>
    );
}

export default App;
